<template>
  <v-container id="case-index-container" class="d-flex flex-column align-stretch pa-0 font-inter" fluid>
    <v-row>
      <v-col>
        <v-card class="py-6 px-6 mt-3" rounded="lg" elevation="0" width="100%">
          <v-row v-if="caseType != 'EditCase'">
            <v-col flex>
              <div class="back-to-projects" @click="handleBackClick">
                <v-icon color="blue">mdi-chevron-left</v-icon>
                <p class="d-flex-inline justify-center align-center ma-0 blue--text font-weight-bold">
                  {{ $t('testruns.create_testrun.back_to_create_testrun') }}
                </p>
              </div>
            </v-col>
            <v-col cols="auto" class="pointer" @click="handleCloseClick">
              <v-icon>mdi-close</v-icon>
            </v-col>
          </v-row>
          <v-row>
            <v-col flex>
              <p class="text-start display-1 font-weight-bold my-2" v-if="caseType == 'EditCase'">
                {{ $t('testruns.testCases') }}
              </p>
              <p class="text-start display-1 font-weight-bold my-2" v-else>{{ $t('testruns.addtestcases') }}</p>
              <div class="mt-6 d-flex">
                <v-chip @click="changeFilter(false)" :class="{ 'blue--text': !tableFilter }" width="200px"
                  :color="!tableFilter ? 'blue-light' : 'gray-light'" label>
                  <div class="font-weight-bold px-2">
                    {{ $t('all') }} <span class="ml-2">{{ casesCount }}</span>
                  </div>
                </v-chip>
                <div class="ml-2">
                  <v-chip @click="changeFilter(true)" :class="{ 'blue--text': tableFilter }" width="200px"
                    :color="tableFilter ? 'blue-light' : 'gray-light'" label>
                    <div class="font-weight-bold px-2">
                      {{ $t('selected') }} <span class="ml-2">{{ selectedCaseCount }}</span>
                    </div>
                  </v-chip>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <CaseManagement :show-collapse="false" :show-create="false" :select-option="tableFilter" :quick-create="false"
      @selectedCases="handleCases" @updateCaseCount="UpdateCases" @folder-select="getCases" :allow-action="false"
      :cases="cases"></CaseManagement>
    <v-row v-if="isDuplicateRunHasData">
      <v-col class="d-flex justify-end">
        <v-btn dark large color="blue" class="text-capitalize font-weight-bold mt-2"
        @click="createTestRuns"
        :loading="isLoading"
        >
          {{ $t('testruns.create_testrun.title') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col class="d-flex justify-end">
        <v-btn dark large color="gray-100" class="text-capitalize font-weight-bold black--text mr-4 mt-2"
          :width="$vuetify.breakpoint.smAndDown ? '100%' : '150px'" @click="handleAddClick('cancel')">
          {{ $t('cancel') }}
        </v-btn>
        <v-btn dark large color="blue" class="text-capitalize font-weight-bold mt-2"
          :width="$vuetify.breakpoint.smAndDown ? '100%' : '150px'" @click="handleAddClick('save')">
          {{ $t('save') }}
        </v-btn>
      </v-col>
    </v-row>
    <RunDiscardDialog :title="ConfirmDialog_Title" :content="ConfirmDialog_Content"
      :content_part2="ConfirmDialog_Contentpart_two" :run_name="ConfirmDialog_RunName"
      :btn_label="ConfirmDialog_btn_label" :color="ConfirmDialog_btn_color" v-model="showAddDialog"
      @close="handleCloseClick" @handleConfirmClick="handleConfirmBtnClick(ConfirmType)" />
  </v-container>
</template>

<script>
import RunDiscardDialog from '@/components/TestRuns/RunDiscardDialog.vue';
import CaseManagement from '@/components/Cases/CaseManagement'
import makeCasesService from '@/services/api/case';
import { createNamespacedHelpers } from 'vuex';
const { mapActions, mapMutations, mapState: mapRunState } = createNamespacedHelpers('run');
const { mapState } = createNamespacedHelpers('user');

export default {
  name: 'TestRunCreate',
  props: ['customCases', 'caseType', 'form'],
  components: {
    CaseManagement,
    RunDiscardDialog,
  },
  data() {
    return {
      showConfirmBackDialog: false,
      showAddDialog: false,
      selectedRun: {},
      tableFilter: false,
      cases: [],
      selectedCases: [],
      milestones: ['Release1.1', 'Release2.2', 'Release3.3'],
      priorities: ['High', 'Medium', 'Low'],
      tags: ['#smoke', '#regression', '#feature', '#security', '#integration'],
      isColumnFilter: false,
      isLoading: false,
      ConfirmDialog_Title: '',
      ConfirmDialog_Content: '',
      ConfirmDialog_Contentpart_two: '',
      ConfirmDialog_btn_label: '',
      ConfirmDialog_btn_color: 'primary',
      ConfirmDialog_RunName: '',
      ConfirmType: '',
      casesCount: 0,
      selectedCaseCount: 0,
      drawer: false,
      selectedCase: null,
    };
  },
  computed: {
    ...mapState(['currentAccount']),
    ...mapRunState(['duplicatedRun']),
    isDuplicateRunHasData() {
      return Object.keys(this.duplicatedRun).length > 0;
    },
  },
  mounted() {
    this.selectedRun = this.$store.state.run.selectedRun;
    this.cases.forEach((cse) => {
      this.$set(cse, 'toBeSelected', false);
    });
  },
  methods: {
    ...mapActions(['createTestRun', 'updateTestRun']),
    ...mapMutations(['UPDATE_SELECTED', 'CLEAR_DUPLICATED_RUN']),
    async getCases(folderUID) {
      const caseService = makeCasesService(this.$api);
      const handle = this.$route.params.handle;
      const projectKey = this.$route.params.key;

      await caseService.getFolderCases(handle, projectKey, folderUID).then(response => {
        this.cases = response.data.cases;

      }).catch(err => {
        console.log(err)
      })
    },
    toggleDrawer() {
      this.drawer = !this.drawer;
    },
    handleConfirmBtnClick(type) {
      this.showAddDialog = false;

      if (type == 'cancel')
        this.$router.replace({
          name: 'Runs',
          params: {
            handle: this.$route.params.handle,
            key: this.$route.params.key
          }
        });
    },
    handleCases(cases) {
      this.selectedCases = cases;
    },
    saveTestRuns() {
      let handle = this.$route.params.handle;
      const selectedCases = this.selectedCases.map(item => item.testCaseRef)
      this.form.customFields.caseCount = selectedCases.length;
      this.form.cases = selectedCases;
      this.updateTestRun({ swal: this.$swal, handle: handle, projectKey: this.$route.params.key, uid: this.$route.params.id, orgUid: 1, payload: this.form })
        .then(() => {
          this.UPDATE_SELECTED({});
          this.$router.push({
            name: 'Runs', params: {
              handle: handle,
              key: this.$route.params.key
            }
          });
        })
        .catch((error) => {
          console.error('Failed to Update Test Run:', error);
        });
    },
    UpdateCases(selectedCount, casesCount) {
      this.selectedCaseCount = selectedCount;
      this.casesCount = casesCount;
    },
    handleBackClick() {
      this.$router.replace({
        name: 'TestRunCreate',
        params: {
          handle: this.$route.params.handle,
          key: this.$route.params.key
        },
      });
    },
    handleAddClick(type) {
      if (type == 'add') {
        this.ConfirmDialog_Title = this.$t('testruns.test_case.addcase.title');
        this.ConfirmDialog_Content = this.$t('testruns.test_case.addcase.content');
        this.ConfirmDialog_Contentpart_two = '';
        this.ConfirmDialog_btn_label = this.$t('add');
        this.ConfirmDialog_btn_color = 'primary';
        this.ConfirmDialog_RunName = '';
        this.ConfirmType = 'add';
        this.showAddDialog = true;
      } else if (type == 'remove') {
        this.ConfirmDialog_Title = this.$t('testruns.test_case.removecase.title');
        this.ConfirmDialog_Content = this.$t('testruns.test_case.removecase.content');
        this.ConfirmDialog_Contentpart_two = '';
        this.ConfirmDialog_btn_label = this.$t('remove');
        this.ConfirmDialog_btn_color = 'danger';
        this.ConfirmDialog_RunName = '';
        this.ConfirmType = 'remove';
        this.showAddDialog = true;
      } else if (type == 'cancel') {
        this.ConfirmDialog_Title = this.$t('testruns.edit_testrun.title');
        this.ConfirmDialog_Content = this.$t('testruns.edit_testrun.content');
        this.ConfirmDialog_Contentpart_two = '';
        this.ConfirmDialog_btn_label = this.$t('testruns.edit_testrun.btn_label');
        this.ConfirmDialog_btn_color = 'primary';
        this.ConfirmDialog_RunName = '';
        this.ConfirmType = 'cancel';
        this.showAddDialog = true;
      } else if (type == 'save') this.saveTestRuns();
    },
    handleCloseClick() {
      this.showConfirmBackDialog = false;
      this.showAddDialog = false;
    },
    handleConfirmClick() {
      this.showConfirmBackDialog = false;
      this.$router.replace({
        name: 'TestRunCreate',
        params: {
          handle: this.$route.params.handle,
          key: this.$route.params.key
        },
      });
    },
    changeFilter(filter) {
      this.tableFilter = filter;
    },
    async createTestRuns() {      // TODO - consolidate create and update between this and RunAddCaseView.vue

      const payload = {
        name: this.duplicatedRun?.name,
        customFields: {
          assign: this.currentAccount.handle,
          description: this.duplicatedRun?.customFields?.description,
          milestone: this.duplicatedRun?.customFields?.milestone,
          priority: this.duplicatedRun?.customFields?.priority,
          status: 'Active',
          tags: this.duplicatedRun?.customFields?.tags,
          progress: 0,
          star: true,
          case_count: 0,
          archived: false,
        },
        externalId: 'testfiesta',
        source: 'testfiesta-web',
        projectKey: this.$route.params.key,
      };

      if (this.selectedCases.length) {
        const selectedCases = this.selectedCases.map(item => item.testCaseRef);
        payload.customFields.case_count = selectedCases.length;
        payload.cases = selectedCases;
      }
      
      try {
        
        this.isLoading = true;
        await this.createTestRun({
          swal: this.$swal,
          handle: this.$route.params.handle,
          projectKey: this.$route.params.key,
          payload,
        });
        this.CLEAR_DUPLICATED_RUN();
        this.$router.push({
          name: this.$route.query?.page ? this.$route.query?.page : 'Runs',
          params: {
            handle: this.$route.params.handle,
            key: this.$route.params.key,
          },
        });
      } catch (error) {
        console.error("Failed to create Test Run:", error);
      } finally {
        this.isLoading = false;
      }
      
    },
  },
};
</script>

<style lang="scss" scoped>
.back-to-projects {
  display: flex;
  cursor: pointer;
  width: max-content;
}

.search_input {
  width: 100%;

  @media screen and (min-width: 600px) {
    width: 300px;
  }
}

.project-logo {
  border-radius: 50%;
  border: 2px dashed grey;
  width: 150px;
  height: 150px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
  cursor: pointer;
  transition: border-color 0.3s;
  background-size: cover;
  background-position: center;
  position: relative;
}

.project-logo:hover {
  border-color: #2196f3;
}

.hovering .edit-icon,
.hovering .delete-icon {
  display: block;
}

.edit-icon,
.delete-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) translateX(-30px);
  display: none;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
}

.delete-icon {
  margin-left: 60px;
}

.project-logo:hover .edit-icon,
.project-logo:hover .delete-icon {
  display: block;
}
</style>
